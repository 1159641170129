var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-col',[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('internal_scoirng_2b')))])]),_c('b-col',{attrs:{"col":"4"}},[_c('b-form-group',{attrs:{"name":"ht-blocking-condition-a","id":"ht-blocking-condition-a","label":_vm.$t('code-block-A') + ':',"label-for":"blocking-a"}},[_c('validation-provider',{attrs:{"name":"HT-Blocking Condition a","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"blocking-a","options":_vm.scoreOptions},model:{value:(_vm.condition_a),callback:function ($$v) {_vm.condition_a=$$v},expression:"condition_a"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"col":"4"}},[_c('b-form-group',{attrs:{"name":"ht-blocking-condition-b","id":"ht-blocking-condition-b","label":_vm.$t('code-block-B') + ':',"label-for":"blocking-b"}},[_c('validation-provider',{attrs:{"name":"HT-Blocking Condition b","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"blocking-b","options":_vm.scoreOptions},model:{value:(_vm.condition_b),callback:function ($$v) {_vm.condition_b=$$v},expression:"condition_b"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"col":"4"}},[_c('b-form-group',{attrs:{"name":"ht-blocking-condition-c","id":"ht-blocking-condition-c","label":_vm.$t('code-block-C') + ':',"label-for":"blocking-c"}},[_c('validation-provider',{attrs:{"name":"HT-Blocking Condition c","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"blocking-c","options":_vm.scoreOptions},model:{value:(_vm.condition_c),callback:function ($$v) {_vm.condition_c=$$v},expression:"condition_c"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"name":"ht-score","id":"ht-score","label":_vm.$t('HT-Score') + ':',"label-for":"ht-score-input"}},[_c('validation-provider',{attrs:{"name":"HT-Score","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ht-score-input"},model:{value:(_vm.score),callback:function ($$v) {_vm.score=$$v},expression:"score"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"name":"ht-pod","id":"ht-pod","label":_vm.$t('HT-POD') + ':',"label-for":"ht-pod-input"}},[_c('validation-provider',{attrs:{"name":"HT-POD","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ht-pod-input"},model:{value:(_vm.pod),callback:function ($$v) {_vm.pod=$$v},expression:"pod"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"justify-content-end d-flex aling-items-end",attrs:{"aling":"end"}},[_c('b-button',{on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("test")))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }