<template>
  <b-card>
    <b-row>
      <b-col md="12">
        <h4>{{ $t("credit_rating") }}</h4>
      </b-col>
      <b-col md="12">
        <b-form-group
          name="active-business"
          id="active-business"
          :label="$t('active_business') + ':'"
          label-for="active-business-input"
        >
          <validation-provider
            #default="{ errors }"
            name="Active Business"
            rules="required"
          >
            <b-form-select
              id="active-business-input"
              v-model="business"
              :options="[0, 1]"
            >
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          name="solvency"
          id="solvency"
          :label="$t('solvency') + ':'"
          label-for="solvency-input"
        >
          <validation-provider
            #default="{ errors }"
            name="Solvency"
            rules="required"
          >
            <b-form-select
              id="solvency-input"
              v-model="solvency"
              :options="solvencyOptions"
            >
            </b-form-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="12">
        <b-form-group
          name="age"
          id="age"
          :label="$t('age_date_(of registry)') + ':'"
          label-for="age-input"
        >
          <validation-provider
            #default="{ errors }"
            name="Age"
            rules="required"
          >
            <b-form-datepicker id="age-input" no-flip v-model="age">
            </b-form-datepicker>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          name="legal-form"
          id="legal-form"
          :label="$t('legal_form') + ':'"
          label-for="legal-form-input"
        >
          <validation-provider
            #default="{ errors }"
            name="legal-form"
            rules="required"
          >
            <b-form-select
              :options="legalFormOptions"
              id="legal-form-input"
              v-model="legal_form"
            ></b-form-select>
            <!-- <b-form-input id="legal-form-input" v-model="legal_form">
          </b-form-input> -->
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6" v-if="legal_form == 'AG' || legal_form == 'GmbH'">
        <b-form-group
          name="Kaptial if AG"
          id="kapital-if-ag"
          :label="$t('kapital_if_AG') + ':'"
          label-for="kapital-if-ag-input"
        >
          <validation-provider
            #default="{ errors }"
            name="kapital-if-ag"
            rules="required"
          >
            <b-form-input id="kapital-if-ag-input" v-model="kapital_ag">
            </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          name="Branche Noga (TBD)"
          id="branche-noga"
          :label="$t('branche_noga_(TBD)') + ':'"
          label-for="branche-noga-input"
        >
          <validation-provider
            #default="{ errors }"
            name="branche-noga"
            rules="required"
          >
            <b-form-select
              id="branche-noga-input"
              v-model="branche_noga"
              :options="[631200, 421100, 0]"
            >
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          name="Person Eng"
          id="person-eng"
          :label="$t('person_eng') + ':'"
          label-for="person-eng-input"
        >
          <validation-provider
            #default="{ errors }"
            name="person-eng"
            rules="required"
          >
            <b-form-input id="person-eng-input" v-model="person_eng">
            </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          name="Person Eng Negativ"
          id="person-eng-negativ"
          :label="$t('person_eng_negativ') + ':'"
          label-for="person-eng-negativ-input"
        >
          <validation-provider
            #default="{ errors }"
            name="person-eng-negativ"
            rules="required"
          >
            <b-form-input
              id="person-eng-negativ-input"
              v-model="person_eng_negativ"
            >
            </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="12"
        class="justify-content-end d-flex aling-items-end"
        aling="end"
      >
        
        <b-button @click="submit">{{ $t("test") }}</b-button>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BButton,
    BFormSelect,
    BFormDatepicker,
  },
  data() {
    return {
      business: "",
      solvency: "",
      age: "",
      legal_form: "",
      kapital_ag: 0,
      kapital_gmbh: "",
      branche_noga: "",
      person_eng: "",
      person_eng_negativ: "",
      other_parameter_tbd: "",
      solvencyOptions: [
        "BB-1",
        "BB-2",
        "BB-3",
        "BB-4",
        "BB-5",
        "BB-6",
        "BB-99",
      ],
      legalFormOptions: ["AG", "GmbH", "Einzelfirma/Sole proprietorship"],
    };
  },
  props: ["id"],
  methods: {
    submit() {
      let data = {
        active: this.business,
        solvency: this.solvency,
        registered_at: this.age,
        legal_form: this.legal_form,
        capital: this.kapital_ag,
        branche: this.branche_noga,
        person_eng: this.person_eng,
        person_eng_negative: this.person_eng_negativ,
        company_id: this.id,
      };
      this.$http.post("/company/admin/scoring/s2", data).then((res) => {
        let text = this.$t("Scoring performed!");
        this.$swal({
          position: "center",
          icon: "success",
          title: text,
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((res) => {
          this.$emit("getAll");
        });
      });
    },
  },
};
</script>
