<template>
  <b-card>
    <b-col>
      <h4>{{ $t("due_diligence") }}</h4>
    </b-col>
    <b-col>
      <b-form-group
        name="debt-collection-report"
        id="debt-collection-report"
        :label="$t('debt_collection_report') + ':'"
        label-for="debt-collection-report-input"
      >
        <validation-provider
          #default="{ errors }"
          name="Debt collection report"
          rules="required"
        >
          <b-form-select
            :options="collectionReportItems"
            id="debt-collection-report-input"
            v-model="collection_report"
          ></b-form-select>

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        name="collateral-value"
        id="collateral-value"
        :label="$t('collateral_value') + ':'"
        label-for="collateral-value-input"
      >
        <validation-provider
          #default="{ errors }"
          name="Collateral Value"
          rules="required"
        >
          <b-form-input id="collateral-value-input" v-model="collateral_value">
          </b-form-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        name="active-debtors"
        id="active-debtors"
        :label="$t('Number_of_active_debtors_in_last_6_months') + ':'"
        label-for="active-debtors-input"
      >
        <validation-provider
          #default="{ errors }"
          name="active-debtors"
          rules="required"
        >
          <b-form-input id="active-debtors-input" v-model="active_debtors">
          </b-form-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <b-col
      v-if="additional"
      class="justify-content-end d-flex aling-items-end"
      aling="end"
    >
      <b-button variant="primary" @click="save" class="mr-2">{{
        $t("save")
      }}</b-button>
      <b-button @click="submit('redirect')">{{
        $t("save_and_score")
      }}</b-button>
    </b-col>
    <b-col
      v-else
      class="justify-content-end d-flex aling-items-end"
      aling="end"
    >
      <b-button @click="submit">{{ $t("test") }}</b-button>
    </b-col>
  </b-card>
</template>
<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BButton,
    BFormSelect,
  },
  props: ["id", "additional"],
  data() {
    return {
      collection_report: "",
      collateral_value: "",
      active_debtors: "",
      other_parameter_tbd: "",
      collectionReportItems: ["No records", "Closed records", "Active records"],
    };
  },
  methods: {
    save() {
      let data = {
        active_records: this.collection_report,
        collateral_value: this.collateral_value,
        active_debtors_in_6_months: this.active_debtors,
        company_id: this.id,
      };

      this.$http.post("/company/admin/scoring3/save", data).then((res) => {
        let text = res.data.message;
        this.$swal({
          position: "center",
          icon: "success",
          title: this.$t(text),
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });

        // this.collection_report = "";
        // this.collateral_value = "";
        // this.active_debtors = "";
      });
    },
    submit(type) {
      let data = {
        active_records: this.collection_report,
        collateral_value: this.collateral_value,
        active_debtors_in_6_months: this.active_debtors,
        company_id: this.id,
      };
      this.$http.post("/company/admin/scoring/s3", data).then((res) => {
        let text = this.$t("Scoring performed!");
        this.$swal({
          position: "center",
          icon: "success",
          title: text,
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((res) => {
          if (type == "redirect") {
            this.$emit("redirectToScoring");
          } else {
            this.$emit("getAll");
          }
        });
      });
    },
  },
};
</script>
