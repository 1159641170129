<template>
  <div>
    <div v-if="overwriteNum == 2">
      <b-form-group>
        <b-form-checkbox v-model="solvency" name="check-button" switch>
          {{ $t("solvency") }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox v-model="capital" name="check-button" switch>
          {{ $t("capital") }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox v-model="personEng" name="check-button" switch>
          {{ $t("person_eng") }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox v-model="personEngNegative" name="check-button" switch>
          {{ $t("person_eng_negative") }}
        </b-form-checkbox>
      </b-form-group>
    </div>
    <div v-if="overwriteNum == 3">
      <b-form-group>
        <b-form-checkbox v-model="debt_collection" name="check-button" switch>
          {{ $t("debt_enforcement") }}
        </b-form-checkbox>
      </b-form-group>
    </div>

    <b-row class="justify-content-end" align="end">
      <div>
        <b-button variant="primary" @click="overWrite">{{
          $t("overwrite")
        }}</b-button>
      </div>
    </b-row>
  </div>
</template>
<script>
export default {
  props: ["overwriteNum"],
  data() {
    return {
      solvency: "",
      capital: "",
      personEng: "",
      personEngNegative: "",
      debt_collection: "",
    };
  },
  methods: {
    overWrite() {
      let data = null;
      if ((this.overwriteNum === 2)) {
        data = {
          solvency: this.solvency ? 1 : 0,
          capital: this.capital ? 1 : 0,
          person_eng: this.personEng ? 1 : 0,
          person_eng_negative: this.personEngNegative ? 1 : 0,
        };
      } 
       if (this.overwriteNum === 3) {
        data = {
          debt_collection: this.debt_collection ? 1 : 0,
        };
      }

      this.$emit("overWrite", data);
    },
    back() {
      this.$emit("closeDialog");
    },
  },
};
</script>
