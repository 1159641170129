<template>
  <div>
    <b-card>
      <b-row class="justify-content-between">
        <b-col class="col-md-6">
          <h2 class="text-blue font-weight-bold ml-1">Scoring</h2>

          <h6 class="col-md-12" v-if="history && history.length > 0">
            {{ $t("current_stage") }}:
            {{ history[history.length - 1].scoring }}
          </h6>
          <h6 class="col-md-12" v-if="history && history.length > 0">
            {{ $t("current_score") }}: {{ history[history.length - 1].score }}
          </h6>
        </b-col>
        <b-col class="col-md-6 text-end justify-content-end" align="end">
          <b-button
            variant="primary"
            class="mr-1"
            @click="toggleForms"
            v-html="
              !showForms ? $t('overwrite_scoring') : $t('back_to_scoring')
            "
          ></b-button>
          <b-button
              variant="primary"
              class=""
              @click="openProductsFeature"
              v-html=" $t('Products Feature')"
          ></b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card no-body v-if="!showForms">
      <b-tabs
          v-show="showScoring"
        v-model="invoiceTab"
        fill
        card
        vertical
        align="start"
        id="my-tabs"
      >
        <b-tab :title="$t('scoring_1')" align="left">
          <template #title>
            <slot class="m-0 p-0 text-left">
              <b-badge variant="primary" class="mr-1" style="width: 20px"
                >1</b-badge
              >
              {{ $t("scoring_1") }}
            </slot>
          </template>
          <b-card-text>
            <b-row v-if="!loader">
              <b-col :cols="12" class="mb-2" @click="nextTab">
                <b-button @click="runScoring('1')" variant="primary mr-1">
                  {{ $t("run_scoring") }} 1
                </b-button>
                <hr />
              </b-col>
              <b-col md="12">
                <h4>{{ $t("scoring_1") }}</h4>
              </b-col>
              <b-col class="col-md-4" v-if="scoring1 != null">
                <h6>{{ $t("last_result") }}:</h6>
                <h6>{{ $t("last_time_performed_on") }}:</h6>
                <h6>{{ $t("performed_by") }}:</h6>
              </b-col>
              <b-col class="col-md-8" v-if="scoring1 != null">
                <h6>{{ scoring1.score }}</h6>
                <h6>{{ scoring1.updated_at | formatDate }}</h6>
                <h6>
                  {{ scoring1.audit_trail.user.name }}
                  {{ scoring1.audit_trail.user.last_name }}
                  <br />
                  <small> {{ scoring1.audit_trail.user.email }} </small>
                </h6>
              </b-col>
            </b-row>
            <b-row v-else>
              <img src="/loader.svg" />
            </b-row>
          </b-card-text>
        </b-tab>
        <b-tab :title="$t('scoring_1B')" :disabled="!scoring1b" class="">
          <template #title>
            <b-badge variant="primary" class="mr-1" style="width: 20px"
              >2</b-badge
            >
            {{ $t("scoring_1B") }}
          </template>
          <b-card-text>
            <b-row v-if="!loader">
              <b-col :cols="12" class="mb-2" @click="nextTab">
                <b-button
                  :disabled="!scoring1b"
                  @click="runScoring('1B')"
                  variant="primary "
                  >{{ $t("run_scoring") }} 1 B</b-button
                >
                <hr />
              </b-col>
              <b-col md="12">
                <h4>{{ $t("scoring_1B") }}</h4>
              </b-col>
              <b-col class="col-md-4" v-if="scoring1b != null">
                <h6>{{ $t("last_result") }}:</h6>
                <h6>{{ $t("last_time_performed_on") }}:</h6>
                <h6>{{ $t("performed_by") }}:</h6>
              </b-col>
              <b-col class="col-md-8" v-if="scoring1b != null">
                <h6>{{ scoring1b.score }}</h6>
                <h6>{{ scoring1b.updated_at | formatDate }}</h6>
                <h6>
                  {{ scoring2b.audit_trail.user.name }}
                  {{ scoring2b.audit_trail.user.last_name }}
                  <br />
                  <small> {{ scoring2b.audit_trail.user.email }} </small>
                </h6>
              </b-col>
            </b-row>
            <b-row v-else>
              <img src="/loader.svg" />
            </b-row>
          </b-card-text>
        </b-tab>
        <b-tab :title="$t('scoring_2')">
          <template #title>
            <b-badge variant="primary" class="mr-1" style="width: 20px"
              >3</b-badge
            >
            {{ $t("scoring_2") }}
          </template>
          <b-card-text>
            <b-row v-if="!loader">
              <b-col :cols="12" class="mb-2" @click="nextTab">
                <b-button @click="runScoring(2)" variant="primary mr-1">
                  {{ $t("run_scoring") }} 2</b-button
                >

                <b-button
                  variant="primary"
                  class="mr-1"
                  v-if="
                    companyData &&
                    companyData.scoring &&
                    companyData.scoring[0] &&
                    (companyData.scoring[0].decision == 'TBD' ||
                      companyData.scoring[0].decision == 'YELLOW')
                  "
                  @click="showOverWriteModal(2)"
                  >{{ $t("overwrite") }}</b-button
                >
                <hr />
              </b-col>
              <b-col md="12">
                <h4>{{ $t("scoring_2") }}</h4>
              </b-col>
              <b-col class="col-md-4" v-if="scoring2 != null">
                <h6>{{ $t("last_result") }}:</h6>
                <h6>{{ $t("last_time_performed_on") }}:</h6>
                <h6>{{ $t("performed_by") }}:</h6>
              </b-col>
              <b-col class="col-md-8" v-if="scoring2 != null">
                <h6>{{ scoring2.score }}</h6>
                <h6>{{ scoring2.updated_at | formatDate }}</h6>
                <h6>
                  {{ scoring2.audit_trail.user.name }}
                  {{ scoring2.audit_trail.user.last_name }}
                  <br />
                  <small> {{ scoring2.audit_trail.user.email }} </small>
                </h6>
              </b-col>
            </b-row>
            <b-row v-else>
              <img src="/loader.svg" />
            </b-row>
          </b-card-text>
        </b-tab>
        <b-tab :title="$t('scoring_3')">
          <template #title class="text-left">
            <b-badge variant="primary" class="mr-1" style="width: 20px"
              >4</b-badge
            >
            {{ $t("scoring_3") }}
          </template>
          <b-card-text>
            <b-row v-if="!loader">
              <b-col :cols="12" class="mb-2" @click="nextTab">
                <b-button @click="runScoring('3')" variant="primary mr-1"
                  >{{ $t("run_scoring") }} 3
                </b-button>

                  <b-button
                  variant="primary"
                  class="mr-1"
                  @click="showOverWriteModal(3)"
                  >{{ $t("overwrite") }}</b-button
                >
                <hr />
              </b-col>
              <b-col md="12">
                <h4>{{ $t("scoring_3") }}</h4>
              </b-col>
              <b-col class="col-md-4" v-if="scoring3 != null">
                <h6>{{ $t("last_result") }}:</h6>
                <h6>{{ $t("last_time_performed_on") }}:</h6>
                <h6>{{ $t("performed_by") }}:</h6>
              </b-col>
              <b-col class="col-md-8" v-if="scoring3 != null">
                <h6>{{ scoring3.score }}</h6>
                <h6>{{ scoring3.updated_at | formatDate }}</h6>
                <h6>
                  {{ scoring3.audit_trail.user.name }}
                  {{ scoring3.audit_trail.user.last_name }}
                  <br />
                  <small> {{ scoring3.audit_trail.user.email }} </small>
                </h6>
              </b-col>
            </b-row>
            <b-row v-else>
              <img src="/loader.svg" />
            </b-row> </b-card-text
        ></b-tab>
      </b-tabs>
      <b-row class="mt-2" v-show="showProductsFeatures">
        <h3 class="text-blue font-weight-bold ml-5">Products Features</h3>

        <b-row class="row mt-1 ml-3 mr-1 pt-1 pb-1 col-12">
          <b-col class="col-2">
          <h6 class="mr-3">  {{ $t("Open Factoring") }}</h6>

          </b-col>
          <b-col class="col-10">
          <b-form-checkbox
              v-model="settings.open_factoring"
              :value="1"
              :unchecked-value="0"
              switch
          >
          </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="row mt-1 ml-3 mr-1 pt-0 pb-1 col-12">
          <b-col class="col-2">
       <h6 class="mr-3">  {{ $t("silent_factoring") }}</h6>
          </b-col>
          <b-col class="col-10">
            <b-form-checkbox
                v-model="settings.silent_factoring"
                :value="1"
                :unchecked-value="0"
                switch
            >
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="row mt-1 ml-3 mr-1 pt-0 pb-1 col-12">
          <b-col class="col-2">
          <h6 class="mr-3">  {{ $t("Recourse") }}</h6>
          </b-col>
          <b-col class="col-10">
          <b-form-checkbox
              v-model="settings.recourse"
              :value="1"
              :unchecked-value="0"
              switch
          >
          </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="row mt-1 ml-3 mr-1 pt-0 pb-1 col-12">
          <b-col class="col-2">
          <h6 class="mr-3">  {{ $t("None Recourse") }}</h6>
          </b-col>
          <b-col class="col-10">
          <b-form-checkbox
              v-model="settings.none_recourse"
              :value="1"
              :unchecked-value="0"
              switch
          >
          </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="row mt-1 ml-3 mr-1 pt-0 pb-1 col-12">
          <b-col class="col-2">
          <h6 class="mr-3">  {{ $t("flash_sale") }}</h6>
          </b-col>
          <b-col class="col-10">
            <b-form-checkbox
                v-model="settings.flash_sale"
                name="check-button"
                :value="1"
                :unchecked-value="0"
                switch
            >
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="row mt-1 ml-3 mr-1 pt-0 pb-1 col-10">
          <b-button
              variant="primary"
              class="mt-2 ml-10"
              @click="updateSettings"
              v-html=" $t('Save')"
          ></b-button>
        </b-row>

      </b-row>
    </b-card>
    <b-row v-else>
      <b-col md="6">
        <Scoring1b :id="id" @getAll="getScoringHistory" />
        <Scoring3 :id="id" @getAll="getScoringHistory" />
      </b-col>
      <b-col md="6">
        <Scoring2 :id="id" @getAll="getScoringHistory" />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <Table
          :url="url"
          :updateData="history"
          :fields="headers"
          :showSearch="false"
          :body="{}"
        >
          <template #cell(company_name)="data">
            <b-link
              v-if="data.item && data.item.company && data.item.company.name"
              @click="showModal(data)"
            >
              {{ data.item.scoring }}
            </b-link>
            <b-button
              v-if="data.item.scoring == 'Scoring 2'"
              class="ml-1"
              @click="downloadPdf(data.item.id)"
              variant="outline-success"
              size="sm"
            >
              <FeatherIcon icon="DownloadIcon"></FeatherIcon>
            </b-button>
          </template>
          <template #cell(decision)="data">
            <span
              :class="
                data.item.decision == $t('REJECT') ||
                data.item.decision == $t('REJECTED')
                  ? 'text-danger'
                  : 'text-success'
              "
            >
              {{ data.item.decision }}
            </span>
          </template>
        </Table>
      </b-col>

      <b-modal
        centered
        v-model="dialog"
        size="lg"
        id="modal-center"
        :title="$t('audit_trial')"
      >
        <b-row v-if="auditTrial">
          <b-col md="8">
            <div>
              {{ $t("scoring") }}:
              <span class="font-weight-bold"> {{ auditTrial.scoring }}</span>
            </div>
          </b-col>
          <b-col md="4">
            <div>
              {{ $t("decision") }}:
              <span class="font-weight-bold"> {{ auditTrial.decision }}</span>
            </div>
          </b-col>

          <b-col md="8">
            <div>
              {{ $t("performed_by") }}:

              <span class="font-weight-bold" v-if="auditTrial.performed_by != 0"
                >{{ auditTrial.user.name }}
                {{ auditTrial.user.last_name }}</span
              >
              <span class="font-weight-bold" v-else>System</span>
            </div>
          </b-col>
          <b-col md="4">
            <div>
              {{ $t("score_points_pre_update") }}:

              <span class="font-weight-bold">{{
                auditTrial.score_points_pre_update
              }}</span>
            </div>
          </b-col>

          <b-col md="8">
            <div>
              {{ $t("performed_at") }}:

              <span class="font-weight-bold">{{
                auditTrial.datetime_of_performance
              }}</span>
            </div>
          </b-col>
          <b-col md="4">
            <div>
              {{ $t("score_points_post_update") }}:

              <span class="font-weight-bold">{{
                auditTrial.score_points_post_update
              }}</span>
            </div>
          </b-col>
          <br />
          <b-col class="mt-1 p-0">
            <table class="table table-striped">
              <tr>
                <td>{{ $t("parameter") }}</td>
                <td>{{ $t("source") }}</td>
                <td>{{ $t("dimension") }}</td>
                <td>{{ $t("value") }}</td>
                <td>{{ $t("point") }}</td>
              </tr>
              <tr
                v-for="(item, index) in auditTrial.information.values"
                :key="index"
              >
                <td class="font-weight-bold" v-html="item[0]"></td>
                <td class="font-weight-bold" v-html="item[1]"></td>
                <td class="font-weight-bold" v-html="item[2]"></td>
                <td class="font-weight-bold" v-html="item[3]"></td>
                <td class="font-weight-bold" v-html="item[4]"></td>
              </tr>
            </table>
          </b-col>
          <b-col md="12">
            <div>
              {{ $t("rule") }}:
              <div v-for="(item, index) in auditTrial.rule" :key="index">
                <span class="font-weight-bold">{{ index }}: {{ item }}</span>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-modal>

      <b-modal hide-footer v-model="overWriteDialog" centered>
        <template #modal-title>
          {{ $t("overwrite_modal") }}
        </template>
        <OverwriteModal @overWrite="overWrite" @closeDialog="closeDialog" :overwriteNum="overwriteNum">
        </OverwriteModal>
      </b-modal>
    </b-row>
  </div>
</template>
<script>
import { BCard, BRow, BCol, BCardTitle } from "bootstrap-vue";
import Scoring1b from "@/components/ScoringForms/Scoring2b.vue";
import Scoring2 from "@/components/ScoringForms/Scoring2.vue";
import Scoring3 from "@/components/ScoringForms/Scoring3.vue";
import Table from "@/components/Common/Table2.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import fileDownload from "js-file-download";
import { FormWizard, TabContent, WizardStep } from "vue-form-wizard";
import OverwriteModal from "@/components/ScoringForms/OverwriteDialog.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import i18n from '@/libs/i18n'

export default {
  components: {
    BCard,
    Scoring1b,
    Scoring2,
    Scoring3,
    BRow,
    BCol,
    BCardTitle,
    Table,
    FormWizard,
    TabContent,
    WizardStep,
    OverwriteModal,
  },

  data() {
    return {
      settings: {
        silent_factoring:"",
        open_factoring: 1,
        flash_sale: "",
        recourse: "",
        none_recourse: "",
      },
      loader: false,
      invoiceTab: 0,
      history: null,
      scoring1: null,
      scoring2: null,
      scoring1b: null,
      scoring3: null,
      auditTrial: null,
      showProductsFeatures: false,
      showForms: false,
      showScoring: true,
      testScore: false,
      overwriteNum:2,
      url: `/company/admin/scoring-history/${this.$route.params.id}`,
      id: "",
      noHistoryFound: "",
      dialog: false,
      overWriteDialog: false,
      options: {
        phone: {
          phone: true,
          phoneRegionCode: "CH",
        },
      },
      companyData: null,
      headers: [
        {
          label: "company",
          value: "company_name",
          key: "company_name",
        },
        {
          label: "score",
          value: "score",
          key: "score",
        },
        {
          label: "decision",
          value: "decision",
          key: "decision",
          align: "center",
        },
      ],
    };
  },
  props: ["makeCall", "nextStepText", "checkStep"],
  watch: {
    makeCall(newValue, oldValue) {
      if (newValue !== oldValue && this.makeCall) {
        this.getScoringHistory();
      }
    },
    checkStep() {
      if (this.nextStepText.includes("Perform Scoring 2")) {
        this.invoiceTab = 2;
      } else if (this.nextStepText.includes("Perform Scoring 1")) {
        this.invoiceTab = 1;
      }
    },
  },
  created() {
    this.getScoringHistory();
    this.getScorings();
    this.getSettings();

    if (this.checkStep) {
      if (this.nextStepText.includes("Perform Scoring 2")) {
        this.invoiceTab = 2;
      } else if (this.nextStepText.includes("Perform Scoring 1")) {
        this.invoiceTab = 1;
      }
    }
    this.$http
      .post(`/company/admin/show/${this.$route.params.id}`)
      .then((res) => {
        this.companyData = res.data.value;
      });
  },
  methods: {
    changeInvoiceFact(type, value) {
      // let data = {
      //   data: value,
      // };
      // this.$http
      //     .post(`/invoice/admin/change/${type}/${this.invoice.id}`, data)
      //     .then((res) => {
      //       if (res) {
      //         this.$toast({
      //           component: ToastificationContent,
      //           props: {
      //             title: i18n.t("success"),
      //             icon: "SuccessIcon",
      //             variant: "success",
      //           },
      //         });
      //       }
      //     });
    },
    async getSettings(){
      await this.$http
          .post(`/company/admin/settings/${this.$route.params.id}`)
          .then((res) => {
            this.settings = res.data.value;
            console.log(this.settings);
          });

    },
    updateSettings(){

      this.$http
          .post(`/company/admin/settings/${this.settings.company_id}/save`, this.settings)
          .then((res) => {
            if(res){
              console.log(res);
              this.showSuccessMessage(res.data.message);
            }
          });
    },
    openProductsFeature(){
      this.showScoring=false;
      this.showProductsFeatures=true;
    },
    overWrite(data) {
      data.company_id = this.$route.params.id;
      this.$http.post(`/company/admin/scoring2/overwrite`, data).then((res) => {
        if (res) {
          this.overWriteDialog = false;
          this.showSuccessMessage(res.data.message);
        }
      });
    },
    showOverWriteModal(type) {
      this.overwriteNum = type
      this.overWriteDialog = true;
    },
    closeDialog() {
      this.overWriteDialog = false;
    },
    downloadPdf(id) {
      this.$http
        .post(
          "/company/admin/downloadCreditReform/" + this.$route.params.id,
          {},
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          fileDownload(response.data, "credit_reform_report.pdf");
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    showSuccessMessage(message) {
      let text = this.$t(message);
      this.$swal({
        position: "center",
        icon:message && message.includes("Monaten") ? "info" : "success",
        title: text ? text : this.$t('Success'),
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((res) => {
        this.getScoringHistory();
      });
    },
    runScoring(scoring) {
      if (scoring == "1") {
        this.$http
          .post(`/company/admin/scoring/1/${this.$route.params.id}`)
          .then((res) => {
            if (res) {
              this.showSuccessMessage(res.data.message);
              this.$emit("updateData", "scoring");
            }
          });
      } else if (scoring == "2") {
        this.loader = true;
        this.$http
          .post(`/company/admin/scoring2`, {
            company_id: this.$route.params.id,
          })
          .catch((err) => {
            this.loader = false;
          })
          .then((res) => {
            if (res) {
              this.showSuccessMessage(res.data.message);
              this.$emit("updateData", "scoring");
              this.loader = false;
            }
          });
      }
    },
    toggleForms() {
      this.getScoringHistory("toggle");
      this.getScorings("toggle");
    },
    getScorings(type) {
      this.$http
        .post(`/company/score/last/1/${this.$route.params.id}`)
        .then((res) => {
          if (res && res.data && res.data.value) {
            this.scoring1 = res.data.value;
          }
        });
      this.$http
        .post(`/company/score/last/1b/${this.$route.params.id}`)
        .then((res) => {
          if (res && res.data && res.data.value) {
            this.scoring1b = res.data.value;
          }
        });
      this.$http
        .post(`/company/score/last/2/${this.$route.params.id}`)
        .then((res) => {
          if (res && res.data && res.data.value) {
            this.scoring2 = res.data.value;
          }
        });
      this.$http
        .post(`/company/score/last/3/${this.$route.params.id}`)
        .then((res) => {
          if (res && res.data && res.data.value) {
            this.scoring3 = res.data.value;
          }
        });
    },
    beforeTabSwitch() {},
    nextTab() {
      if (this.$refs.wizard) {
        this.$refs.wizard.nextTab();
      }
    },
    showTesting() {
      // this.showForms = true;
    },
    showModal(data) {
      this.getAuditTrial(data);
    },
    getScoringHistory(toggle) {
      this.loader = true;
      this.id = this.$route.params.id;
      this.url = `/company/admin/scoring-history/${this.id}`;
      this.$http
        .post(`/company/admin/scoring-history/${this.$route.params.id}`)
        .catch((err) => {
          this.loader = false;
        })
        .then((res) => {
          if (res && res.data && res.data.value) {
            this.history = res.data.value;

            if (toggle == "toggle") {
              this.showForms = !this.showForms;
            }else{
              //  this.$emit("updateData", "scoring");
            }
            this.loader = false;
          }
        });
    },
    getAuditTrial(data) {
      this.$http
        .post(`/company/admin/scoring-trail/${data.item.id}`)
        .then((res) => {
          if (res && res.data && res.data.value) {
            this.auditTrial = res.data.value;
            this.auditTrial.information = JSON.parse(
              this.auditTrial.information
            );
            this.auditTrial.rule = JSON.parse(this.auditTrial.rule);
          } else {
            this.noHistoryFound = res.data.message;
          }
          this.dialog = true;
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
#my-tabs__BV_tab_controls_ {
  align-items: start;
}
</style>
