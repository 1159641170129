var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("credit_rating")))])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"name":"active-business","id":"active-business","label":_vm.$t('active_business') + ':',"label-for":"active-business-input"}},[_c('validation-provider',{attrs:{"name":"Active Business","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"active-business-input","options":[0, 1]},model:{value:(_vm.business),callback:function ($$v) {_vm.business=$$v},expression:"business"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"name":"solvency","id":"solvency","label":_vm.$t('solvency') + ':',"label-for":"solvency-input"}},[_c('validation-provider',{attrs:{"name":"Solvency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"solvency-input","options":_vm.solvencyOptions},model:{value:(_vm.solvency),callback:function ($$v) {_vm.solvency=$$v},expression:"solvency"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"name":"age","id":"age","label":_vm.$t('age_date_(of registry)') + ':',"label-for":"age-input"}},[_c('validation-provider',{attrs:{"name":"Age","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"age-input","no-flip":""},model:{value:(_vm.age),callback:function ($$v) {_vm.age=$$v},expression:"age"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"name":"legal-form","id":"legal-form","label":_vm.$t('legal_form') + ':',"label-for":"legal-form-input"}},[_c('validation-provider',{attrs:{"name":"legal-form","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.legalFormOptions,"id":"legal-form-input"},model:{value:(_vm.legal_form),callback:function ($$v) {_vm.legal_form=$$v},expression:"legal_form"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.legal_form == 'AG' || _vm.legal_form == 'GmbH')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"name":"Kaptial if AG","id":"kapital-if-ag","label":_vm.$t('kapital_if_AG') + ':',"label-for":"kapital-if-ag-input"}},[_c('validation-provider',{attrs:{"name":"kapital-if-ag","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kapital-if-ag-input"},model:{value:(_vm.kapital_ag),callback:function ($$v) {_vm.kapital_ag=$$v},expression:"kapital_ag"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1399716247)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"name":"Branche Noga (TBD)","id":"branche-noga","label":_vm.$t('branche_noga_(TBD)') + ':',"label-for":"branche-noga-input"}},[_c('validation-provider',{attrs:{"name":"branche-noga","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"branche-noga-input","options":[631200, 421100, 0]},model:{value:(_vm.branche_noga),callback:function ($$v) {_vm.branche_noga=$$v},expression:"branche_noga"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"name":"Person Eng","id":"person-eng","label":_vm.$t('person_eng') + ':',"label-for":"person-eng-input"}},[_c('validation-provider',{attrs:{"name":"person-eng","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"person-eng-input"},model:{value:(_vm.person_eng),callback:function ($$v) {_vm.person_eng=$$v},expression:"person_eng"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"name":"Person Eng Negativ","id":"person-eng-negativ","label":_vm.$t('person_eng_negativ') + ':',"label-for":"person-eng-negativ-input"}},[_c('validation-provider',{attrs:{"name":"person-eng-negativ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"person-eng-negativ-input"},model:{value:(_vm.person_eng_negativ),callback:function ($$v) {_vm.person_eng_negativ=$$v},expression:"person_eng_negativ"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"justify-content-end d-flex aling-items-end",attrs:{"md":"12","aling":"end"}},[_c('b-button',{on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("test")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }